<script>
export default {
  methods: {},
  mounted() {
    var password = document.getElementById("password-input");
    password.onchange = this.validatePassword;
    document
      .querySelectorAll("form .auth-pass-inputgroup")
      .forEach(function (item) {
        item.querySelectorAll(".password-addon").forEach(function (subitem) {
          subitem.addEventListener("click", function () {
            var passwordInput = item.querySelector(".password-input");
            if (passwordInput.type === "password") {
              passwordInput.type = "text";
            } else {
              passwordInput.type = "password";
            }
          });
        });
      });

    var myInput = document.getElementById("password-input");
    var letter = document.getElementById("pass-lower");
    var capital = document.getElementById("pass-upper");
    var number = document.getElementById("pass-number");
    var length = document.getElementById("pass-length");

    // When the user clicks on the password field, show the message box
    myInput.onfocus = function () {
      document.getElementById("password-contain").style.display = "block";
    };

    // When the user clicks outside of the password field, hide the password-contain box
    myInput.onblur = function () {
      document.getElementById("password-contain").style.display = "none";
    };

    // When the user starts to type something inside the password field
    myInput.onkeyup = function () {
      // Validate lowercase letters
      var lowerCaseLetters = /[a-z]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
      }

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }

      // Validate length
      if (myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }
    };
  },
};
</script>

<template>
  <!-- auth-page wrapper -->
  <div
    class="
      auth-page-wrapper auth-bg-cover
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    "
  >
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden m-0">
              <div class="row justify-content-center g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <router-link to="/" class="d-block">
                          <img
                            src="@/assets/images/logo-light.png"
                            alt=""
                            height="18"
                          />
                        </router-link>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i
                            class="ri-double-quotes-l display-4 text-success"
                          ></i>
                        </div>

                        <div
                          id="qoutescarouselIndicators"
                          class="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div class="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="0"
                              class="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#qoutescarouselIndicators"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          </div>
                          <div
                            class="
                              carousel-inner
                              text-center text-white-50
                              pb-5
                            "
                          >
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">
                                " Size özel fiyatlar ve tekliflerle alışverişin tadını çıkarın! "
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Özel teklif fırsatları ile alışverişe başlayın! <br />elektromarketim.com #heranındayanında "
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Kişisel müşteri temsilcisi ve kurumsal iletişim danışmanlarımızla alışverişe başla "
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Hesap Oluştur</h5>
                      <p class="text-muted">
                        Teklif sistemi için hesap oluşturun.
                      </p>
                    </div>

                    <div class="mt-4">
                      <form class="needs-validation" novalidate>
                        <div class="mb-3">
                          <label for="useremail" class="form-label"
                            >E-posta <span class="text-danger">*</span></label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="useremail"
                            placeholder="E-posta adresinizi giriniz"
                            required
                          />
                          <div class="invalid-feedback">Please enter email</div>
                        </div>
                        <div class="mb-3">
                          <label for="username" class="form-label"
                            >Ad Soyad <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Adınızı ve soyadınızı giriniz"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter username
                          </div>
                        </div>

                        <div class="mb-3">
                          <label class="form-label" for="password-input"
                            >Parola</label
                          >
                          <div class="position-relative auth-pass-inputgroup">
                            <input
                              type="password"
                              class="form-control pe-5 password-input"
                              onpaste="return false"
                              placeholder="Parolanızı giriniz"
                              id="password-input"
                              aria-describedby="passwordInput"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <button
                              class="
                                btn btn-link
                                position-absolute
                                end-0
                                top-0
                                text-decoration-none text-muted
                                password-addon
                              "
                              type="button"
                              id="password-addon"
                            >
                              <i class="ri-eye-fill align-middle"></i>
                            </button>
                            <div class="invalid-feedback">
                              Please enter password
                            </div>
                          </div>
                        </div>

                        <div class="mb-4">
                          <p class="mb-0 fs-12 text-muted fst-italic">
                            Kaydolarak Elektromarketim
                            <a
                              href="#"
                              class="
                                text-primary text-decoration-underline
                                fst-normal
                                fw-medium
                              "
                              >Kullanım Koşullarını</a
                            >
                            kabul etmiş olursunuz.
                          </p>
                        </div>
                        <div
                          id="password-contain"
                          class="p-3 bg-light mb-2 rounded"
                        >
                          <h5 class="fs-13">Parolanız içermelidir:</h5>
                          <p id="pass-length" class="invalid fs-12 mb-2">
                            En az <b>8 karakter</b>
                          </p>
                          <p id="pass-lower" class="invalid fs-12 mb-2">
                            En az bir <b>küçük</b> harf (a-z)
                          </p>
                          <p id="pass-upper" class="invalid fs-12 mb-2">
                            En az bir <b>büyük</b> karakter (A-Z)
                          </p>
                          <p id="pass-number" class="invalid fs-12 mb-0">
                            En az bir <b>rakam</b> (0-9)
                          </p>
                        </div>
                        <div class="mt-4">
                          <button class="btn btn-success w-100" type="button">
                            Hesap Oluştur
                          </button>
                        </div>
                      </form>
                    </div>

                    <div class="mt-5 text-center">
                      <p class="mb-0">
                        Zaten hesabınız var mı?
                        <router-link
                          to="/oturum-ac"
                          class="
                            fw-semibold
                            text-primary text-decoration-underline
                          "
                        >
                          Oturum açın
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} Elektromarketim.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>